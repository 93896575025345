<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="keyword">
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        header-align="center"
        align="center"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="部门"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="fillName"
        header-align="center"
        align="center"
        label="填报人"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="fxr"
        header-align="center"
        align="center"
        label="发现人"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="隐患等级"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="describeInfo"
        header-align="center"
        align="center"
        label="隐患描述"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="findDate"
        header-align="center"
        align="center"
        label="隐患发现时间"
        width="110"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.findDate">{{
            scope.row.findDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="reformResponsibilityName"
        header-align="center"
        align="center"
        label="整改负责人"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="reformTimeLimit"
        header-align="center"
        align="center"
        label="整改时限"
        width="110"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.reformTimeLimit">{{
            scope.row.reformTimeLimit.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isUrge"
        header-align="center"
        align="center"
        label="是否已督办"
        width="95"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isUrge == 0">否</span>
          <span
            @click="urge(scope.row.id)"
            style="color: blue; cursor: pointer"
            v-if="scope.row.isUrge == 0 && scope.row.status == 8"
            >督办</span
          >
          <span v-if="scope.row.isUrge == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态 "
        width="230"
      >
        <template slot-scope="scope">
          <span>{{
            [
              "已上报，等待部门负责人审核",
              "部门负责人已同意入库，等待质安部管理员审核",
              "部门负责人已拒绝入库",
              "一般隐患，质安部管理员已同意入库，等待填写整改方案",
              "较大或重大隐患，质安部管理员已同意入库，等待质安部负责人审核",
              "质安部管理员已拒绝入库",
              "质安部负责人已同意入库，等待填写整改方案",
              "质安部负责人已拒绝入库",
              "已填写整改方案",
              "已发起验收申请",
              "验收不通过，请重新填写整改方案",
              "验收通过，已归档保存",
            ][scope.row.status]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="250"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 1)"
            >查看</el-button
          >
          <el-button
            v-if="scope.row.status == 0"
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.status == 0"
            type="primary"
            size="mini"
            @click="bmfzrsp(scope.row.id)"
            >部门审核</el-button
          >
          <el-button
            v-if="scope.row.status == 1"
            type="primary"
            size="mini"
            @click="zabglyspHandle(scope.row.id)"
            >质安部管理员审批</el-button
          >
          <el-button
            v-if="scope.row.status == 4"
            type="primary"
            size="mini"
            @click="zabfzrspHandle(scope.row.id)"
            >质安部负责人审批</el-button
          >
          <el-button
            v-if="
              scope.row.status == 3 ||
              scope.row.status == 6 ||
              scope.row.status == 10
            "
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 2)"
            >填写整改方案</el-button
          >
          <el-button
            v-if="scope.row.status == 8"
            type="primary"
            size="mini"
            @click="launchCheckHandle(scope.row.id)"
            >发起验收申请</el-button
          >
          <el-button
            v-if="scope.row.status == 9"
            type="primary"
            size="mini"
            @click="checkHandle(scope.row.id)"
            >验收</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <el-dialog title="部门审核" :visible.sync="bmfzrspVisible" width="30%">
      <el-input v-model="bmshyj" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            bmfzrspVisible = false;
            bmfzrspSubmit(1);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            bmfzrspVisible = false;
            bmfzrspSubmit(0);
          "
          >同意</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="质安部管理员审核"
      :visible.sync="zabglyVisible"
      width="30%"
    >
      <el-input v-model="zabgly" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            zabglyVisible = false;
            zabglyspSubmit(1);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            zabglyVisible = false;
            zabglyspSubmit(0);
          "
          >同意</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="质安部负责人审核"
      :visible.sync="zabfzrVisible"
      width="30%"
    >
      <el-input v-model="zabfzr" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            zabfzrVisible = false;
            zabfzrspSubmit(1);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            zabfzrVisible = false;
            zabfzrspSubmit(0);
          "
          >同意</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="验收" :visible.sync="yanshouVisible" width="30%">
      <el-input v-model="yanshou" placeholder="请输入您的验收意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            yanshouVisible = false;
            checkSubmit(1);
          "
          >不合格</el-button
        >
        <el-button
          type="primary"
          @click="
            yanshouVisible = false;
            checkSubmit(0);
          "
          >合格</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from "./hidden-add-or-update";

export default {
  data() {
    return {
      zabglyVisible: false,
      bmfzrspVisible: false,
      zabfzrVisible: false,
      yanshouVisible: false,
      dataForm: {
        keyword: "",
        did: "",
      },
      id: "",
      bmshyj: "",
      zabgly: "",
      zabfzr: "",
      yanshou: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,

      departmentOptions: [], //部门列表选项
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },

    // 督办
    urge(id) {
      this.$prompt("", "请输入督办内容", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        this.$http.hidden
          .urge({ urgeProjectId: id, type: 0, content: value })
          .then((res) => {
            this.$message.success(res.msg);
            this.getDataList();
          });
      });
    },
    // 验收 0：同意  1：拒绝
    checkSubmit(flag) {
      if (flag == 1) {
        this.$http.hidden
          .check({ id: this.id, flag: 1, content: this.yanshou })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.hidden
          .check({ id: this.id, flag: 0, content: this.yanshou })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示验收
    checkHandle(id) {
      this.id = id;
      this.yanshouVisible = true;
      return;
      this.$confirm("请提交您的验收意见", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "合格",
        cancelButtonText: "不合格",
      })
        .then(() => {
          this.$http.hidden.check({ id, flag: 0 }).then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            this.$http.hidden.check({ id, flag: 1 }).then((data) => {
              if (data && data.code === 0) {
                this.$message.success(data.msg);
                this.getDataList();
              }
            });
          }
        });
    },
    // 发起验收申请
    launchCheckHandle(id) {
      this.$http.hidden.launchCheck({ id }).then((data) => {
        if (data && data.code === 0) {
          this.$message.success(data.msg);
          this.getDataList();
        }
      });
    },
    // 质安部负责人审批 0：同意  1：拒绝
    zabfzrspSubmit(flag) {
      if (flag == 1) {
        this.$http.hidden
          .zabfzrApproval({ id: this.id, flag: 1, content: this.zabfzr })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.hidden
          .zabfzrApproval({ id: this.id, flag: 0, content: this.zabfzr })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示质安部负责人审批
    zabfzrspHandle(id) {
      this.id = id;
      this.zabfzrVisible = true;
      return;
      this.$confirm("请提交您的审核意见", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
      })
        .then(() => {
          this.$http.hidden.zabfzrApproval({ id, flag: 0 }).then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            this.$http.hidden.zabfzrApproval({ id, flag: 1 }).then((data) => {
              if (data && data.code === 0) {
                this.$message.success(data.msg);
                this.getDataList();
              }
            });
          }
        });
    },
    // 质安部管理员审批 0：同意  1：拒绝
    zabglyspSubmit(flag) {
      if (flag == 1) {
        this.$http.hidden
          .zabglyApproval({ id: this.id, flag: 1, content: this.zabgly })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.hidden
          .zabglyApproval({ id: this.id, flag: 0, content: this.zabgly })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示质安部管理员审批
    zabglyspHandle(id) {
      this.id = id;
      this.zabglyVisible = true;
      return;
      this.$confirm("请提交您的审核意见", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
      })
        .then(() => {
          this.$http.hidden.zabglyApproval({ id, flag: 0 }).then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            this.$http.hidden.zabglyApproval({ id, flag: 1 }).then((data) => {
              if (data && data.code === 0) {
                this.$message.success(data.msg);
                this.getDataList();
              }
            });
          }
        });
    },
    // 部门负责人审批 0：同意  1：拒绝
    bmfzrspSubmit(flag) {
      if (flag == 1) {
        this.$http.hidden
          .bmfzrApproval({ id: this.id, flag: 1, content: this.bmshyj })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.hidden
          .bmfzrApproval({ id: this.id, flag: 0, content: this.bmshyj })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示部门负责人审批
    bmfzrsp(id) {
      this.id = id;
      this.bmfzrspVisible = true;
      return;
      this.$confirm("请提交您的审核意见", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
      })
        .then(() => {
          this.$http.hidden.bmfzrApproval({ id, flag: 0 }).then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            this.$http.hidden.bmfzrApproval({ id, flag: 1 }).then((data) => {
              if (data && data.code === 0) {
                this.$message.success(data.msg);
                this.getDataList();
              }
            });
          }
        });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.hidden.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id, flag) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, flag);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.hidden
          .delete({
            id,
          })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
      });
    },
  },
};
</script>
